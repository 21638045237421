"use client"

import LogoutIcon from "@mui/icons-material/Logout"
import MenuIcon from "@mui/icons-material/Menu"
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { FeatureFlags, useFeatureFlag } from "@tc/ui-feature-flags"
import { signOut } from "next-auth/react"
import dynamic from "next/dynamic"
import NextLink from "next/link"
import React, { Fragment } from "react"
import { PermissionCode } from "../TeoPermission/PermissionCodes"
import { useTeoPermission } from "../TeoPermission/TeoPermission"
const TemplateQuickSearch = dynamic(() =>
  import("../TemplateQuickSearch").then((mod) => mod.TemplateQuickSearch),
)

type HeaderLink = {
  display: string
  link: string
  flags?: string[]
  permissionCodes?: PermissionCode[]
}

const HeaderItemsStart: HeaderLink[] = [
  {
    display: "Templates",
    link: "/search",
    flags: [],
    permissionCodes: [
      PermissionCode.template_search_view,
      PermissionCode.template_create_update,
      PermissionCode.template_download_document,
      PermissionCode.template_detail_history,
      PermissionCode.template_upload_document,
    ],
  },
  {
    display: "Business Rules",
    link: "/business-rules/search",
    flags: [FeatureFlags.TrancheTwo, FeatureFlags.TrancheThree], // need to be defined in the feature flag table
    permissionCodes: [
      PermissionCode.business_rules_search_view,
      PermissionCode.business_rules_update,
    ],
  },
  {
    display: "Imports",
    link: "/import-certificates",
    flags: [FeatureFlags.TrancheTwo],
    permissionCodes: [
      PermissionCode.imports_search_view,
      PermissionCode.imports_download_xml,
    ],
  },
]

export const TopNavbar = () => {
  const { isEnabled } = useFeatureFlag()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const { hasPermission } = useTeoPermission([])
  const onSignOut = async () => {
    await signOut()
  }

  const showingMenus = HeaderItemsStart.filter((item) => {
    const show = isEnabled(item.flags)
    const allow = hasPermission(item.permissionCodes)

    return show && allow
  })

  return (
    <Fragment key={"header-navbar"}>
      <AppBar color="default">
        <Toolbar sx={{ minWidth: 600 }}>
          <Grid container>
            <Box sx={{ mr: 2, display: { sm: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Grid item xs sx={{ display: { xs: "none", sm: "flex" } }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                role="menu"
              >
                <Typography variant="h6" role="menuitem">
                  TEO
                </Typography>
                {showingMenus.map((item) => {
                  return (
                    <NextLink
                      key={item.link}
                      href={item.link}
                      passHref
                      legacyBehavior
                    >
                      <MenuItem component="div">{item.display}</MenuItem>
                    </NextLink>
                  )
                })}
              </Stack>
            </Grid>
            <Grid item xs>
              <Box textAlign="right">
                <Button
                  color="inherit"
                  onClick={onSignOut}
                  startIcon={<LogoutIcon />}
                  aria-label="sign out"
                >
                  Sign out
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
            },
          }}
        >
          <Stack sx={{ textAlign: "center" }} spacing={4}>
            <Box>
              <Typography variant="h6" sx={{ my: 2 }}>
                TEO
              </Typography>
              <Divider />
            </Box>

            <List>
              {HeaderItemsStart.map((item) => {
                const show = isEnabled(item.flags)
                return (
                  <Fragment key={item.link}>
                    {show && (
                      <ListItem disablePadding key={item.link}>
                        <NextLink href={item.link} passHref legacyBehavior>
                          <ListItemButton component="a">
                            <ListItemText primary={item.display} />
                          </ListItemButton>
                        </NextLink>
                      </ListItem>
                    )}
                  </Fragment>
                )
              })}
            </List>
            <Button
              color="inherit"
              onClick={onSignOut}
              startIcon={<LogoutIcon />}
              aria-label="sign out"
            >
              Sign out
            </Button>
          </Stack>
        </Drawer>
      </AppBar>
      <Toolbar />
    </Fragment>
  )
}
